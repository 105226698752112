import service from '@/services'

// Module Vuex gudang
export default {
  namespaced: true,
  state: {
    datas: [],
    vehicles: [],
    activeKendaraan: null,
    my_sales_rutes: [],
  },
  getters: {
    findVehicle: state => id => state.vehicles.find(v => v.id == id),
  },
  mutations: {
    SET_SALES_RUTES(state, datas) {
      state.my_sales_rutes = datas
    },
    SET_GUDANG_MOBIL(state, data) {
      state.vehicles = data
    },
    SET_DATA(state, data) {
      state.datas = data
    },
    SET_ACTIVE_KENDARAAN(state, data) {
      state.activeKendaraan = data
    },
  },
  actions: {
    async getMySalesRute({ commit }, params) {
      try {
        const config = {
          url: '/gudang-sales-rute',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_SALES_RUTES', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: '/gudang',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getDataById({}, id) {
      try {
        const config = {
          url: `/gudang/${id}`,
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: '/input/gudang',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getDataStokMobil({ commit }, params = {}) {
      try {
        const config = {
          url: '/stok-mobil',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}

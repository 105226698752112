import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from "@/auth/utils";
import akun from "./routes/akun";
import dashboard from "./routes/dashboard";
import pages from "./routes/pages";
import barang from "./routes/barang";
import stockIn from "./routes/stock-in";
import level from "./routes/level";
import menu from "./routes/menu";
import cetak from "./routes/cetak";
import ceklist from "./routes/ceklist-kendaraan";
import disposalrusak from "./routes/disposal-rusak";
import penerimaanBarang from "./routes/penerimaan-barang";
import ajuanbarang from "./routes/ajuan-barang";
import penyimpanan from "./routes/penyimpanan";
import mutasi from "./routes/mutasi";
import perbaikanKendaraan from "./routes/perbaikan-kendaraan";
import kendaraanBarang from "./routes/kendaraan-barang";
import bop from "./routes/bop";
import returKonsumen from "./routes/retur-konsumen";
import pembelian from "./routes/pembelian";
import hutangPerusahaan from "./routes/hutang-perusahaan";
import asset from "./routes/asset";
import laporan from "./routes/laporan";
import returSupplier from "./routes/retur-supplier";
//OWNER
import owner from "./routes/affiliate/owner";
import hr from "./routes/affiliate/hr";
//CABANG
import cabang from "./routes/affiliate/cabang";
// KOMISI
import komisi from "./routes/affiliate/komisi";
// REWARD
import reward from "./routes/affiliate/reward";
// AJUAN
import ajuan from "./routes/affiliate/ajuan";
// finance
import finance from "./routes/affiliate/finance";
// harga pengiriman
import hargaPengiriman from "./routes/express/harga";
import transaksioff from "./routes/transaksioff";
import shippingOrder from "./routes/express/shipping-order";
import shipmentAssgiment from "./routes/express/shipping-assignment";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "my-dashboard" } },
    ...laporan,
    ...returSupplier,
    ...asset,
    ...hutangPerusahaan,
    ...returKonsumen,
    ...pembelian,
    ...kendaraanBarang,
    ...dashboard,
    ...perbaikanKendaraan,
    ...bop,
    ...pages,
    ...barang,
    ...stockIn,
    ...level,
    ...menu,
    ...cetak,
    ...ceklist,
    ...disposalrusak,
    ...penerimaanBarang,
    ...penyimpanan,
    ...ajuanbarang,
    ...mutasi,
    ...akun,
    ...finance,
    //HR
    ...owner,
    ...hr,
    //Cabang
    ...cabang,
    ...komisi,
    ...reward,
    ...ajuan,
    // Express
    ...hargaPengiriman,
    ...transaksioff,
    ...shippingOrder,
    ...shipmentAssgiment,
    {
      path: "/rute",
      name: "rute",
      component: () => import("@/views/pages/rute/List.vue"),
      meta: {
        pageTitle: "Daftar Rute",
        authRequired: true,
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
    {
      path: "/apk-versioning",
      name: "apk-versioning",
      component: () => import("@/views/pages/apk-versioning/List.vue"),
      meta: {
        pageTitle: "APK Versioning",
        authRequired: true,
      },
    },
    {
      path: "/setting",
      name: "setting",
      component: () => import("@/views/pages/setting/Setting.vue"),
      meta: {
        pageTitle: "Setting Aplikasi",
        authRequired: true,
      },
    },
    {
      path: "/rank-sales",
      name: "rank-sales",
      component: () => import("@/views/pages/rank-sales/List.vue"),
      meta: {
        pageTitle: "Rank Sales",
        authRequired: true,
      },
    },
  ],
});

window.popStateDetected = false;
window.addEventListener("popstate", () => {
  window.popStateDetected = true;
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
  const isBackButton = window.popStateDetected;
  window.popStateDetected = false;

  if (to.meta.authRequired && !isLoggedIn) {
    const { pathname } = window.location;
    return next(`/login?to=${pathname}`);
  }

  // validate if loggedIn user access non-authorized menu/feature
  if (to.meta.authRequired && to.meta.module != "public" && to.path != "/dashboard" && !canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: "auth-login" });

    // If logged in => not authorized
    return next("/not-authorized");
  }

  if (to.meta.authRequired && !isLoggedIn) {
    return router.push("/login");
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    // const userData = getUserData()
    // next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    return router.push("/");
  }

  if (isBackButton) {
    return next();
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;

import service from '@/services'

export default {
  namespaced: true,
  state: {
    peminjamans: [],
    history_peminjamans: [],
    penyimpanans: [],
  },
  getters: {
    searchPeminjaman: state => id => state.peminjamans.find(item => item.id == id),
  },
  mutations: {
    SET_PEMINJAMAN(state, datas) {
      state.peminjamans = datas
    },
    SET_HISTORY_PEMINJAMAN(state, datas) {
      state.history_peminjamans = datas
    },
    SET_PENYIMPANAN_PEMINJAMAN(state, datas) {
      state.penyimpanans = datas
    },
  },
  actions: {
    async getShippingOrder({ commit }, params = {}) {
      try {
        const config = {
          method: 'get',
          baseURL: process.env.VUE_APP_API2,
          url: '/shipping-order',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.results ? response.data.results : []
        commit('SET_PEMINJAMAN', result)
        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getRiwayatPeminjaman({ commit }, params = {}) {
      try {
        const config = {
          method: 'get',
          baseURL: process.env.VUE_APP_API2,
          url: '/asset-riwayat-peminjaman',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.results ? response.data.results : []
        commit('SET_HISTORY_PEMINJAMAN', result)
        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getShippingOrderById({}, id) {
      try {
        const config = {
          method: 'get',
          baseURL: process.env.VUE_APP_API2,
          url: `/shipping-order/${id}`,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        // console.log('response', response.data.results)
        return Promise.resolve(response.data.results)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async savePeminjaman({}, payload) {
      const { id } = payload
      const { type } = payload
      try {
        let url = '/shipping-order'
        let method = 'post'

        if (type === 'edit') {
          url = `/shipping-order/${id}`
          method = 'put'
          // Remove id from payload
          const { id: _, ...restPayload } = payload
          payload = restPayload
          console.log('restPayload', payload)
        }
        if (type === 'delete') {
          url = `/shipping-order/${id}`
          method = 'delete'
        }

        const config = {
          baseURL: process.env.VUE_APP_API2,
          url,
          method,
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)
        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async cancelPeminjaman({}, payload) {
      try {
        const config = {
          baseURL: process.env.VUE_APP_API2,
          url: '/input/asset-riwayat-peminjaman',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(error)
      }
    },
  },
}
